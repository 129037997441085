/**
 * Forget Password
 */
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";

// redux action
import { handleForgotPassword } from 'Redux/actions/auth/index'
import { NotificationManager } from 'react-notifications';
function ForgotPassword2(props) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleKeyPress = (event) => {
    const pattern = /[0-9]/; // Only allow numeric characters
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  };

  const settings = useSelector((state) => state.settings);
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState('')
  const store = useSelector(state => state.authReducer) || { showPassword }
  const [count, setCount] = useState(30);



  useEffect(() => {
    if (store?.isPasswordExist !== undefined) {
      setShowPassword(store.showPassword)
    } else {
      setShowPassword(false)
    }
  }, [dispatch, store?.isPasswordExist, store?.showPassword])

  useEffect(() => {
    setInterval(() => setCount((counter) => counter - 1), 1000);
  }, [setCount]);
  if (count === -1) {
    setCount(0);
  }

  const onSubmit = data => {
    data.mobileNumber = "+91" + data.mobileNumber;
    if (typeof phone !== "undefined") {
      dispatch(handleForgotPassword(props, data, phone))
    } else {
      // props.history.push('/otp')
      NotificationManager.error("Please enter mobile number !")
    }
  }

  /**
   * Function to login user using Firebase
   */

  const { isDarkModeActive } = settings;

  return (
    <div>
      <div className="session-wrapper">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={4} className="login-wrap">
            <div className="login-wrapper text-center">
              <div className="w-100">
                <div className="session-logo">
                  {isDarkModeActive ? (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="100"
                      src={require(`assets/Images/sra_logo.png`).default}
                    />
                  ) : (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="100"
                      src={require(`assets/Images/sra_logo.png`).default}
                    />
                  )}
                </div>
                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                  <Box mb={3}>
                    <TextField
                      fullWidth
                      id="mobileNumber"
                      name='mobileNumber'
                      type="tel"
                      label="Mobile Number"
                      placeholder="Please enter your 10-digit mobile number."
                      inputProps={{ maxLength: 10 }} // Limit input to 10 characters
                      className={errors.mobileNumber ? "is-invalid" : ""}
                      onKeyPress={handleKeyPress} // Handle key press to disallow non-numeric characters
                      {...register("mobileNumber", {
                        required: "Mobile number is required.",
                        minLength: {
                          value: 10,
                          message: "Mobile number must be 10 digits.",
                        },
                      })}
                      onChange={(event) => {
                        // Handle onChange here
                        const mobileNumber = event.target.value;
                        setPhone(mobileNumber)
                        // Do something with the mobileNumber value if needed
                      }}
                    />
                    {errors.password && (
                      <Box
                        component="span"
                        color="error.main"
                        textAlign="left"
                        display="block"
                        fontSize="subtitle2.fontSize"
                        pt={1}
                      >
                        {errors.password.message}
                      </Box>
                    )}
                  </Box>

                  <Box mb="40px" pt="20px">
                    <div id="recaptcha-container"></div>
                    <Button
                      color="primary"
                      className="btn-block blockBtn w-100"
                      variant="contained"
                      size="large"
                      type="submit"
                    >
                      Send OTP
                    </Button>
                  </Box>
                </form>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={8}
            style={{
              position: "relative", // Required to ensure the overlay is constrained to this Grid
              backgroundImage:
                "url(" + require("assets/Images/bg.webp").default + ")",
              backgroundSize: "cover",
              backgroundPosition: "center left",
            }}
            className="img-session"
          >
            {/* Gray overlay */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent gray mask
                zIndex: 1, // Ensures it sits above the background image
              }}
            ></div>

            <div className="login-content" style={{ position: "relative", zIndex: 2 }}>
              <Box
                fontSize="h1.fontSize"
                fontWeight="h1.fontWeight"
                mb={4}
                color="common.white"
              >
                Not To Worry, We Got You Covered
              </Box>
              <Button
                variant="contained"
                component={Link}
                to="/signin"
                className="btn-block-md"
              >
                Back To Sign In
              </Button>
            </div>
          </Grid>

        </Grid>
      </div>
    </div>
  );
}

export default ForgotPassword2;
